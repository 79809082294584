import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { BehaviorSubject, combineLatest, interval, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, take, tap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { urlValidator } from '@vendasta/forms';
import { TranslateService } from '@ngx-translate/core';
import { AutoCompleteCallback, AutocompleteService, DropdownItem } from '../../autocomplete.service';
import { CompanyAddressInfo } from '../sign-up-form.component';
import { PhoneInputComponent, SelectInputOptionInterface } from '@vendasta/galaxy/input';
import { AddressAPIService } from '@vendasta/address';
import { ACCOUNT_INFO_STEP_ONE, ACCOUNT_INFO_STEP_TWO, CurrentStepService } from '../../currentstep.service';

@Component({
  selector: 'app-sign-up-step-two-form',
  styleUrls: ['../sign-up-form.component.scss'],
  templateUrl: './sign-up-step-two-form.component.html',
})
export class SignUpStepTwoFormComponent implements OnInit, AutoCompleteCallback {
  @Input() form: UntypedFormGroup;
  @Input() formFieldEnabled: { [key: string]: BehaviorSubject<boolean> };
  @Input() companyAddressDetails: CompanyAddressInfo = {} as CompanyAddressInfo;
  @Input() countryControl: UntypedFormControl = new UntypedFormControl('');
  @Output() submitStepTwo = new EventEmitter<void>();

  @ViewChildren('firstNameComponent') firstNameInput: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChild('phoneNumberComponent') phoneNumberComponent: PhoneInputComponent;
  @ViewChildren('stepTwoFormContainer') private stepTwoFormContainer: QueryList<ElementRef<HTMLInputElement>>;

  ACCOUNT_INFO_STEP_ONE = ACCOUNT_INFO_STEP_ONE;
  ACCOUNT_INFO_STEP_TWO = ACCOUNT_INFO_STEP_TWO;

  numberOfEmployees = ['1-2', '3-10', '11-30', '31-100', '101+'].map((value) => ({ label: value, value: value }));
  allCountryOptions$: Observable<SelectInputOptionInterface[]>;
  countryOptions$: Observable<SelectInputOptionInterface[]>;
  countryLabelHack$ = interval(100).pipe(map(() => 'move-label-out-of-input-after-set-by-company'));
  selectedCountryCode$: Observable<string>;
  txtQueryChanged: Subject<string> = new Subject<string>();
  locations: DropdownItem[];
  urlValidator = [];
  passwordValidators = [
    {
      validatorFn: Validators.minLength(8),
      errorMessage: this.translateService.instant('SIGNUP.VALIDATION.PASSWORD_MIN_LENGTH'),
    },
  ];
  currentStep$ = this.currentStepService.stepChange$.pipe(map((step) => step?.current));

  constructor(
    private translateService: TranslateService,
    private autocompleteService: AutocompleteService,
    private zone: NgZone,
    private readonly addressService: AddressAPIService,
    private currentStepService: CurrentStepService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.urlValidator = [
      {
        validatorFn: urlValidator,
        errorMessage: this.translateService.instant('SIGNUP.VALIDATION.INVALID_URL'),
      },
    ];

    this.allCountryOptions$ = this.addressService.listAllCountryOptions().pipe(
      take(1),
      shareReplay({ refCount: true, bufferSize: 1 }),
      map((countries) => {
        if (!countries) {
          return [];
        }
        return countries.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }),
      map((countries) =>
        countries.map((country) => ({
          value: country?.code || '',
          label: country?.name || '',
        })),
      ),
    );
    this.countryOptions$ = combineLatest([
      this.allCountryOptions$,
      this.form.get('countrySearchControl').valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([allCountries, search]) => {
        search = (search || '').toLowerCase().trim();
        return allCountries.filter((country) => country.label.toLowerCase().includes(search));
      }),
      distinctUntilChanged(),
    );
    this.form.get('countrySearchControl').setAsyncValidators([
      (control) => {
        return this.allCountryOptions$.pipe(
          map((validCountries) => {
            const valid = validCountries.some((vc) => vc.label === control.value);
            if (valid) {
              return null;
            }
            return { invalidCountrySelection: true };
          }),
        );
      },
    ]);

    this.txtQueryChanged.pipe(debounceTime(200), distinctUntilChanged()).subscribe((model) => {
      this.autocompleteService.getChoices(model, (choices: DropdownItem[]): void => {
        if (choices) {
          this.zone.run(() => {
            this.locations = choices;
          });
        }
      });
    });
  }

  @HostListener('window:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    const keyEnter = 13;
    if (event.keyCode === keyEnter) {
      switch (this.currentStepService.stepChange$$.value.current) {
        case ACCOUNT_INFO_STEP_ONE:
          this.onSubmit();
          break;
        case ACCOUNT_INFO_STEP_TWO:
          this.onSubmit();
          break;
      }
    }
  }

  onMapReady(googleMap: google.maps.Map): void {
    this.autocompleteService.init(googleMap, this);
  }

  companyOnSelected(location: DropdownItem): void {
    if (location) {
      this.autocompleteService.onSelect(location.placeId);
    }
  }

  companyDisplayValue(location: DropdownItem): string {
    if (location) {
      return location.mainText;
    }
    return '';
  }

  companyOnFieldChange(event: KeyboardEvent): void {
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp' || event.code === 'Enter') {
      return;
    }
    const companyName = this.form.get('companyName').value;
    if (companyName) {
      this.txtQueryChanged.next(companyName as string);
    } else {
      this.zone.run(() => {
        this.locations = [];
      });
      this.clearSelectedBusiness();
    }
  }

  clearSelectedBusiness(): void {
    this.companyAddressDetails = {} as CompanyAddressInfo;
  }

  countrySelected(c: string): void {
    this.countryOptions$
      .pipe(
        tap((countries) => {
          const selected = countries.find((country) => country.label === c);
          if (selected) {
            this.countryControl.setValue(selected.value);
            this.companyAddressDetails.country = selected.label;
            this.companyAddressDetails.country_short = selected.value;
          }
        }),
        take(1),
      )
      .subscribe();
  }

  setBusinessSelectedDetailsCallback(place_id: string, place: google.maps.places.PlaceResult): void {
    const address_components = place.address_components;
    const street_number = this.extractFromAddress(address_components, 'street_number');
    const address = this.extractFromAddress(address_components, 'route');
    const country = this.extractFromAddress(address_components, 'country');
    const countryCode = this.extractFromAddress(address_components, 'country', 'short_name');
    this.companyAddressDetails.place_id = place_id;
    this.companyAddressDetails.name = place.name;
    this.companyAddressDetails.address =
      (street_number != null ? street_number : '') + ' ' + (address != null ? address : '');
    this.companyAddressDetails.city = this.extractFromAddress(address_components, 'locality');
    this.companyAddressDetails.zip = this.extractFromAddress(address_components, 'postal_code');
    this.companyAddressDetails.country = country;
    this.companyAddressDetails.country_short = countryCode;
    this.companyAddressDetails.region = this.extractFromAddress(address_components, 'administrative_area_level_1');
    this.companyAddressDetails.region_short = this.extractFromAddress(
      address_components,
      'administrative_area_level_1',
      'short_name',
    );

    if (country) {
      this.form.get('countrySearchControl').setValue(country);
      this.form.get('countrySearchControl').markAsDirty();
      this.form.get('countrySearchControl').markAllAsTouched();
      this.form.get('countrySearchControl').updateValueAndValidity();
    }
    if (countryCode) {
      this.countryControl.setValue(countryCode);
    }

    this.countryOptions$
      .pipe(
        tap((countries) => {
          const findCompanyCountry = countries.find((c) => c.label === country);
          if (!findCompanyCountry) {
            this.formFieldEnabled.countrySearchControl.next(true);
          }
        }),
        take(1),
      )
      .subscribe();
  }

  extractFromAddress(
    components: google.maps.GeocoderAddressComponent[],
    type: string,
    name_type = 'long_name',
  ): string {
    return (
      components
        .filter((component) => component.types.indexOf(type) === 0)
        .map((item) => item[name_type])
        .pop() || null
    );
  }

  markControlsAsDirtyAndTouched(form: UntypedFormGroup, controlNames: string[]): void {
    controlNames.forEach((controlName) => {
      const control = form.get(controlName);
      if (control.pristine && control.errors) {
        control.markAsDirty();
        control.markAsTouched();
        control.updateValueAndValidity();
      }
      if (controlName === 'phoneNumber' && !control.valid) {
        this.phoneNumberComponent?.rawInputControl?.markAsDirty();
        this.phoneNumberComponent?.rawInputControl?.markAsTouched();
        this.phoneNumberComponent?.rawInputControl?.updateValueAndValidity();
      }
    });
  }

  onSubmit(): void {
    const stepOneInputs = ['firstName', 'lastName', 'password'];
    if (this.currentStepService.stepChange$$.value.current === ACCOUNT_INFO_STEP_ONE) {
      this.markControlsAsDirtyAndTouched(this.form, stepOneInputs);
      if (this.form.get('firstName').errors || this.form.get('lastName').errors || this.form.get('password').errors) {
        return;
      }
      this.goToNextStep();
    } else {
      const stepTwoInputs = ['companyName', 'countrySearchControl', 'numberOfEmployees', 'phoneNumber', 'websiteUrl'];
      this.markControlsAsDirtyAndTouched(this.form, [...stepOneInputs, ...stepTwoInputs]);
      if (this.form.invalid) {
        return;
      }
      this.submitStepTwo.emit();
    }
  }

  goToNextStep(): void {
    const displayedSteps = new Map();
    for (const [key, subject] of Object.entries(this.formFieldEnabled)) {
      displayedSteps.set(key, subject.value);
    }
    this.currentStepService.incrementStep(Object.fromEntries(displayedSteps));
  }
}
