import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { ProductAnalyticsService } from '@vendasta/product-analytics';
import { useCaseStepEnabled } from './sign-up-form/sign-up-form.service';

export const SIGNUP_PROVIDER_STEP = 1;
export const ACCOUNT_INFO_STEP_ONE = 2;
export const ACCOUNT_INFO_STEP_TWO = 3;
export const USE_CASE_QUESTION_STEP = 4;
export const SIGNUP_LOADING_SCREEN = 5;
export const VERIFY_EMAIL_STEP = 6;


export interface StepChangeEvent {
  current: number;
  previous: number;
}

@Injectable({ providedIn: 'root' })
export class CurrentStepService {
  stepChange$$ = new BehaviorSubject<StepChangeEvent>({ current: 1, previous: 1 });
  stepChange$ = this.stepChange$$
    .asObservable()
    .pipe(distinctUntilChanged((a, b) => a.previous === b.previous && a.current === b.current));

  reachedSignupLoadingScreen$: Observable<boolean>;
  useCaseStepEnabled: boolean = useCaseStepEnabled;

  private trackChangeStep(displayedFields?: { [key: string]: boolean }): void {
    const event = {
      maxSteps: VERIFY_EMAIL_STEP,
      previousStep: this.stepChange$$.value.previous,
      currentStep: this.stepChange$$.value.current,
      numberOfDisplayedFields: undefined,
      displayedFields: undefined,
      useCaseQuestion: false,
    };

    if (displayedFields && this.stepChange$$.value.current === ACCOUNT_INFO_STEP_TWO) {
      event.numberOfDisplayedFields = Object.values(displayedFields).filter((value) => value).length;
      event.displayedFields = displayedFields;
    } else if (this.stepChange$$.value.current === USE_CASE_QUESTION_STEP) {
      event.useCaseQuestion = true;
    }
    this.trackingService.trackEvent('SelfSignUp:change-step', 'SelfSignUp', 'change-step', 0, event);
  }

  constructor(private readonly trackingService: ProductAnalyticsService) {
    this.reachedSignupLoadingScreen$ = this.stepChange$.pipe(
      map((step) => {
        return step?.current === SIGNUP_LOADING_SCREEN;
      }),
    );
  }

  incrementStep(displayedFields?: { [key: string]: boolean }): void {
    this.stepChange$
      .pipe(
        take(1),
        tap((stepChanged) => {
          if (stepChanged?.current < VERIFY_EMAIL_STEP) {
            this.stepChange$$.next({
              previous: stepChanged?.current,
              current: this.getNextStep(stepChanged.current),
            });
            this.trackChangeStep(displayedFields);
          }
        }),
      )
      .subscribe();
  }

  getNextStep(step): number {
    // A/B test for use case question
    if (step === ACCOUNT_INFO_STEP_TWO && !this.useCaseStepEnabled) {
      return SIGNUP_LOADING_SCREEN;
    }
    return ++step;
  }

  getPreviousStep(step): number {
    let previousStep = step - 1;
    while (previousStep >= SIGNUP_PROVIDER_STEP) {
      previousStep--;
    }
    return previousStep;
  }

  decrementStep(): void {
    this.stepChange$
      .pipe(
        take(1),
        tap((stepChanged) => {
          if (stepChanged?.current >= SIGNUP_PROVIDER_STEP) {
            this.stepChange$$.next({
              previous: stepChanged.current,
              current: this.getPreviousStep(stepChanged.current),
            });
          }
        }),
      )
      .subscribe();
  }
}
